<template>
    <main>
        <page-header-compact>Configuracion Items - Secciones - Aiepi</page-header-compact>
        <div class="container-xl px-4 mt-n15">
        
        <div class="card mt-4">
            <div class="card-header bg-light">
                <div class="row">
                    <div class="col-6 ">
                        SECCION : {{secciones.nombre}}
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                        <button @click="modificar=false; abrirModal();" type="button" class="btn btn-primary ">
                            Crear Item&nbsp;<i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body ">
            <div > 
                        
                    </div>
                    <div class="modal fade" id="modalItemAiepi" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{{tituloModal}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form >
                                        <div class="form-group">
                                            <label class="form-label">Tipo</label>
                                            <select  v-model="item_aiepi.id_aiepi_tipo"    class="form-control"  :class="{'is-invalid':$v.item_aiepi.id_aiepi_tipo.$error && $v.item_aiepi.id_aiepi_tipo.$dirty}">
                                                <option :value="item.id" v-for="item in tipos" :key="item.id">
                                                    {{ item.nombre }}
                                                </option >
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Nombre</label>
                                            <input type="text" class="form-control" v-model="item_aiepi.nombre" placeholder="Nombre" :class="{'is-invalid':$v.item_aiepi.nombre.$error && $v.item_aiepi.nombre.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Sexo</label>
                                            <select  v-model="item_aiepi.sexo"   class="form-control" :class="{'is-invalid':$v.item_aiepi.sexo.$error && $v.item_aiepi.sexo.$dirty}" >
                                                <option value="M">M</option>
                                                <option value="F">F</option>
                                                <option value="A">A</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Descripcion</label>
                                            <input type="text" class="form-control" v-model="item_aiepi.descripcion" placeholder="Descripcion" :class="{'is-invalid':$v.item_aiepi.descripcion.$error && $v.item_aiepi.descripcion.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Obligatorio</label>
                                            <select  v-model="item_aiepi.obligatorio"   class="form-control" :class="{'is-invalid':$v.item_aiepi.obligatorio.$error && $v.item_aiepi.obligatorio.$dirty}" >
                                                <option value="S">SI</option>
                                                <option value="N">NO</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Explicacion</label>
                                            <select  v-model="item_aiepi.explicacion"   class="form-control" :class="{'is-invalid':$v.item_aiepi.explicacion.$error && $v.item_aiepi.explicacion.$dirty}" >
                                                <option value="S">SI</option>
                                                <option value="N">NO</option>
                                            </select>
                                        </div>
                                        <div class="" v-if="tipoSeleccionado==='OPC'">
                                            <div class="card-header bg-light ">Posibles opciones</div>
                                            <div class="card-body">
                                                <div class="form-group row" >
                                                    <div class="col-8">
                                                        <label class="form-label">Opciones</label>
                                                        <input type="text" class="form-control" v-model="opcion.valor" :class="{'is-invalid':$v.opcion.valor.$error && $v.opcion.valor.$dirty}" >
                                                    </div>
                                                    <div class="col-4 mt-4 py-2"> 
                                                        <button class="btn btn-primary " @click.prevent="agregarOpcion();" type="button">Agregar Opciones&nbsp;<i class="fa fa-plus"></i></button>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <table class="table table-striped table-sm ">
                                                        <tr class="table-primary">
                                                            <th>Opcion</th>
                                                            <th></th>
                                                        </tr>
                                                        <tr v-for="(item,i) in item_aiepi.opciones" :key="i">
                                                            <td>{{item.valor}}</td>
                                                            <td>
                                                                <button class="btn btn-outline-danger"  @click.prevent="removerOpcion()">
                                                                    <i class="fas fa-trash-alt"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-success" @click="guardar()">
                                        <i class="fas fa-save"></i>&nbsp;Guardar
                                     </button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-warning col-12" v-if="!items.length">
                        No se han registrado items...
                    </div>
                            
                    <div class="table table-responsive" v-else>
                        <table class="table table-striped table-bordered ">
                            <tr class="table-primary">
                                <th>Tipo</th>
                                <th>Nombre</th>
                                <th>Sexo</th>
                                <th>Posibles Opciones</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>                          
                            <tr v-for="item in items" :key="item.id">
                                <td>{{item.tipo_aiepi.nombre}}</td>
                                <td>{{item.nombre}}</td>
                                <td>{{item.sexo}}</td>
                                <td v-if="item.tipo_aiepi.codigo==='OPC'"><span  v-for="item in item.aiepi_opcion_item" :key="item.id">{{item.valor}} </span></td>
                                <td v-else><p class="alert alert-warning">Este item no tiene opciones</p></td>
                                 <td v-if="item.activo==='S'">
                                    <h4><span class="badge badge-success"><i class="fa fa-check"></i> Activo</span></h4>
                                </td>
                                <td v-if="item.activo==='N'">
                                    <h4><span class="badge badge-danger"><i class="fa fa-ban"></i> Inactivo</span></h4>
                                </td>
                                <td>
                                   <div class="form-row">
                                        <div class="form-group px-2">
                                            <button @click="modificar=true; abrirModal(item);" class="btn btn-outline-primary lift" >
                                                <i class="fa fa-edit fa-fw"></i>Editar
                                            </button>
                                        </div>
                                        <div class="form-group px-2">
                                            <button @click="cambiarEstado(item.id);" class="btn btn-outline-warning lift" >
                                                <i class="fa fa-sync-alt fa-fw"></i>Cambiar Estado
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
            </div>         
        </div>
        </div>
    </main>
</template>
<script>

import $ from 'jquery';
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";
import seccionAiepiService from '../../../../../services/seccionAiepiService';
import itemsAiepiService from '../../../../../services/itemsAiepiService';
import tipoAiepiService from '../../../../../services/tipoAiepiService';
import opcionAiepiItemService from '../../../../../services/opcionAiepiItemService';
export default {
    components: {PageHeaderCompact},
    data(){
        return{
            id:0,
            modificar:true,
            tituloModal:'',
            item_aiepi:{
                id:'',
                sexo:'',
                id_aiepi_tipo:'',
                id_aiepi_seccion:'',
                descripcion : '',
                obligatorio : '',
                explicacion: '',
                nombre : '',
                opciones:[],
            },
            idSeccion:'',
            secciones:{},
            items:{},
            tipos:[],
            opcion:{
                valor: '',
            },

        }
    },
    validations (){
        return {
            item_aiepi:{
                id_aiepi_tipo:{required},
                sexo:{required},
                descripcion:{required},
                obligatorio:{required},
                explicacion:{required},
                nombre:{required},
            },
            opcion:{
                valor:{required},
            }
        }
    },
    methods:{
        async abrirModal(datos={}){
            $('#modalItemAiepi').modal('show');
            if(this.modificar){
                this.item_aiepi.id=datos.id;
                this.tituloModal="Modificar Items Aiepi";
                this.item_aiepi.id_aiepi_tipo=datos.id_aiepi_tipo;
                this.item_aiepi.id_aiepi_seccion=datos.id_aiepi_seccion;
                this.item_aiepi.sexo=datos.sexo;
                this.item_aiepi.descripcion=datos.descripcion;
                this.item_aiepi.obligatorio=datos.obligatorio;
                this.item_aiepi.nombre=datos.nombre;
                this.item_aiepi.explicacion=datos.explicacion;
               
                if(this.tipoSeleccionado==='OPC'){
                    const response=await opcionAiepiItemService.showByItem(this.item_aiepi.id);
                    this.item_aiepi.opciones = response.data;
                }
            }else{
                this.id=0;
                this.tituloModal="Crear Item Aiepi";
                this.item_aiepi.id_aiepi_tipo="";
                this.item_aiepi.id_aiepi_seccion="";
                this.item_aiepi.sexo="";
                this.item_aiepi.obligatorio="";
                this.item_aiepi.descripcion="";
                this.item_aiepi.nombre="";
                this.item_aiepi.explicacion="";
                this.item_aiepi.opciones=[];
            }
        },
        async cargarSeccion(id){
            const response = await seccionAiepiService.show(id);
            this.secciones=response.data;
        },
        async cargarItemsSeccion(id){
            const response = await itemsAiepiService.showBySec(id);
            this.items=response.data;
           
        },
        async cargarTipoAiepi(){
            const response = await tipoAiepiService.index();
            this.tipos=response.data;
        },
        async guardar(){ 
            this.item_aiepi.id_aiepi_seccion=this.idSeccion;
            this.$v.item_aiepi.$touch();
            if(this.$v.item_aiepi.$invalid){
                return 
            }
            
            if(this.modificar){
                this.LoaderSpinnerShow();
                await itemsAiepiService.update(this.item_aiepi);
                this.LoaderSpinnerHide();
                Swal.fire('Datos actualizados con exito','', 'success');
                this.limpiarCampos();
                $('#modalItemAiepi').modal('hide');
                this.cargarSeccion(this.item_aiepi.id_aiepi_seccion);
                
            }else{
                this.LoaderSpinnerShow();
                await itemsAiepiService.store(this.item_aiepi);
                this.LoaderSpinnerHide();
                Swal.fire('Datos guardados con exito','', 'success');
                this.limpiarCampos();
                $('#modalItemAiepi').modal('hide');
                this.cargarSeccion(this.idSeccion);
            }
            
        },
        async cambiarEstado(id){
            this.LoaderSpinnerShow();
            await itemsAiepiService.estado(id);
            this.LoaderSpinnerHide();
            Swal.fire('Estado actualizado con exito','', 'success');
            this.cargarSeccion(this.idSeccion);
        },
        agregarOpcion(){
            this.$v.opcion.$touch();
            if (this.$v.opcion.$invalid) {
                return;
            }
            this.item_aiepi.opciones.push({...this.opcion});
            this.opcion={};
        },
        removerOpcion(index) {
            this.item_aiepi.opciones.splice(index, 1);
        },
        limpiarCampos(){
            this.opcion={};
        }
    },
    async created(){
        this.idSeccion=this.$route.params.id;
        this.cargarSeccion(this.idSeccion);
        this.cargarItemsSeccion(this.idSeccion);
        this.cargarTipoAiepi();
    },
    computed:{
        tipoSeleccionado(){
            const tipo= this.tipos.find(x=>x.id===this.item_aiepi.id_aiepi_tipo);
            return tipo===undefined?'':tipo.codigo;
        }
    }
}
</script>

